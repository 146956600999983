.mat-exp-table {

  --mat-expansion-header-text-weight: 400;

  .mat-expansion-panel-header-title {
    color: rgba(0, 0, 0, 0.54);
  }

  // temp

  .mat-accordion .mat-header-row-expansion {
    padding-left: 1.5rem;
    padding-right: 3rem;
    border-bottom: none;
    display: flex;
  }

  .mat-expansion-panel-header.mat-row {
    border-bottom: none;
  }

  //copied from https://github.com/angular/material2/blob/master/src/lib/table/table.scss
  $mat-header-row-height: 56px;
  $mat-row-height: 48px;
  $mat-row-horizontal-padding: 24px;

  // .mat-table {
  //   display: block;
  // }

  .mat-header-row-expansion {
    min-height: $mat-header-row-height;
  }

  .mat-row {
    min-height: $mat-row-height;
  }

  .mat-row, .mat-header-row-expansion {
    border-bottom-width: 1px;
    border-bottom-style: none !important;
    align-items: center;
    padding: 0 $mat-row-horizontal-padding;
    box-sizing: border-box;

    // Workaround for https://goo.gl/pFmjJD in IE 11. Adds a pseudo
    // element that will stretch the row the correct height. See:
    // https://connect.microsoft.com/IE/feedback/details/802625
    &::after {
      display: inline-block;
      min-height: inherit;
      content: '';
    }
  }

  // keeping table cell text alligned verticaly allignd
  .mat-cell-expansion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2px;

    .mat-cell-expansion-text {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
    }
  }

  .mat-cell-expansion, .mat-header-cell-expansion {
    flex: 1;
    overflow: hidden;
    word-break: normal;
  }

  td.mat-cell-expansion {
    border-bottom-style: none !important;
  }

  td.mat-cell, td.mat-footer-cell, th.mat-header-cell, .mat-content, .mat-header-cell {
    text-align: center !important;
  }

  .mat-cell, .mat-footer-cell {
    font-size: 13px !important;
  }
}
