tb-tabs {
  mat-tab-group {
    height: 100%;
    .mat-tab-body-wrapper {
      flex: 1;
    }
  }
  &.align-start .mat-tab-labels {
    justify-content: start;
  }
  &.align-center .mat-tab-labels {
    justify-content: center;
  }
  &.align-end .mat-tab-labels {
    justify-content: end;
  }
  &.align-stretch .mat-tab-labels {
    justify-content: space-around;
    .mat-tab-label {
      flex: 1 1 auto;
    }
  }
}
