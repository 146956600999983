tb-data-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;

  table {
    width: 100%;

    td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
      padding:0 15px 0 15px;
    }
  }
}
