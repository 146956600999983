tb-button {
  display: inline-block;

  > button {
    width: 100%;

    > span > mat-progress-spinner.mat-progress-spinner {
      display: inline-block;
    }
  }
}

div.mat-menu-panel {
  min-height: auto;
}
