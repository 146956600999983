tb-select-field {
  display: inline-block;

  mat-form-field {
    width: 100%;
  }
  
}

.filter-option > .mat-pseudo-checkbox {
  display: none !important;
}
