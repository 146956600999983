@use '@angular/material' as mat;
@import '@angular/material/theming';

tb-tabbed-page {

  .mat-tab-body-content {
    display: flex;
    height: auto;
  }

  tb-tabs {
    flex: 1;
    .mat-tab-group {
      min-height: 100%;
    }
    .mat-tab-header {
      position: fixed;
      left: 0;
      top: 64px;
      z-index: 5;
      width: 100%;
      border-bottom: none;
      @include mat.elevation(2);
    }
    .mat-tab-body-wrapper {
      margin-top: calc(64px + 48px);
    }
  }

  &.xsmall {
    tb-tabs {
      .mat-tab-header {
        top: 56px;
      }
      .mat-tab-body-wrapper {
        margin-top: calc(56px + 48px);
        padding: 8px;
      }
    }
  }

  &.small, &.medium {
    tb-tabs {
      .mat-tab-body-wrapper {
        padding: 16px;
      }
    }
  }

  &.small.landscape {
    tb-tabs {
      .mat-tab-header {
        top: 48px;
      }
      .mat-tab-body-wrapper {
        margin-top: calc(48px + 48px);
      }
    }
  }

  &.large {
    tb-tabs {
      .mat-tab-body-wrapper {
        padding: 24px;
      }
    }
  }
}
