tb-expansion-panel {
  --mat-expansion-header-text-weight: 400;

  mat-expansion-panel.mat-expansion-panel:not([class*='mat-expanded']) {
    border-radius: inherit !important;
  }
}
tb-expansion-panel:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
tb-expansion-panel:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
