tb-texthtml {
  .style {
    max-width: 100%;

    img {
      max-width: 100%;

    }
  }
}
