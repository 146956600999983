tb-toolbar {
  width: 100%;

  mat-toolbar {

    mat-toolbar-row {
      justify-content: center;

      .toolbar-content-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  &.tb-widget.outlined {
    border-left-style: none;
    border-top-style: none;
    border-right-style: none;
  }

}
