tb-text {
  overflow-wrap: break-word;
  overflow: auto;

  &.uppercase {
    text-transform: uppercase;
  }

  &.capitalise {
    text-transform: capitalize;
  }

  &.no-wrap {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    overflow-wrap: normal;
  }
}
