
tb-chips-field{
  mat-chip {
    max-width: 99%;
  }

  .chipMargin {
    margin: 0 8px;
  }

  .mat-form-field-infix {
    width: 100%;
  }
}

.chip-list {
  width: 100%;
}
