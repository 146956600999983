tb-label-row {
  display: flex;
  align-items: stretch;
  padding: 10px;

  > * {
    &:nth-child(1) {
      flex: 1 1 20%;
    }
    &:nth-child(2) {
      flex: 1 1 80%;
    }
  }
}
