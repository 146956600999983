tb-radio-group-field {
  display: inline-block;
  width: 100%;

  mat-form-field {
    width: 100%;
  }

  mat-label.mat-tooltip-trigger {
    pointer-events: auto;
  }
}
