$whitePrimary: #fafafa;
$whiteSecondary: #f2f2f2;
$grayShade: rgb(146, 145, 145);

$spans: 0 1 2 3 4 5 6;
$levels: 0 1 2 3 4;

@each $span in $spans {
  .rowspan-#{$span} {
    grid-row: auto / span #{$span};
    height: $span * 60px;
  }
  .colspan-#{$span} {
    grid-column: auto / span #{$span};
  }
}

@each $level in $levels {
  .level-#{$level} {
    top: $level * 60px;
  }
}

tb-tree-table {
  display: grid;
  width: 100%;
  overflow-x: auto;
  overflow-y: visible;
  border-top: solid 1px $grayShade;
  grid-auto-rows: min-content;

  [role="row"] [role="gridcell"]:last-child, [role="columnheader"].rightmost {
    border-right: solid 1px $grayShade;
  }

  [role="columnheader"],
  [role="gridcell"] {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $grayShade;
    border-bottom: 1px solid $grayShade;
  }

  [role="columnheader"] {
    position: sticky;
    padding: 0 3px;
    text-align: center;
    background-color: $whitePrimary;
    z-index: 10;
  }

  [role="row"] {
    display: contents;

    > [role="gridcell"]:first-child {
      justify-content: flex-start;
      min-width: max-content;
      padding-right: 12px;

      &:not(.expandable)::before {
        content: "";
        width: 40px;
        height: 40px;
      }
    }

    &:hover [role="gridcell"] {
      background-color: $whiteSecondary;
    }
  }

  [role="gridcell"] {
    color: rgba(0, 0, 0, 0.8);

    &.expandable {
      user-select: none;
    }

    &:not(.shade) {
      background-color: white;
    }
  }

  .shade {
    background-color: $whiteSecondary;
  }

  &.firstColumnSticky {
    [role="row"] [role="gridcell"]:first-child {
      position: sticky;
      left: 0;
      z-index: 5;
    }

    [role="columnheader"]:first-child {
      left: 0;
      z-index: 15;
    }

    [role="row"] [role="gridcell"]:first-child,
    [role="columnheader"]:first-child {
      border-right: solid 1px $grayShade;
    }

    [role="row"] [role="gridcell"]:nth-child(2),
    [role="columnheader"].leftmostOfSecondColumn {
      border-left: none;
    }
  }
}
